.organizationchart-demo .card {
    overflow-x: auto;
}

.organizationchart-demo .p-organizationchart .p-person {
    padding: 2px;
    border: 0 none;
    background-color: transparent;

}


.labelsorganigrama {
    font-weight: 600;
    top: -25px;
    position: relative;
    left: 50px;
}

.panel {
    background-color: #495ebb;
}

.organizationchart-demo .p-organizationchart .node-header,
.organizationchart-demo .p-organizationchart .node-content {
    padding: .5em .7rem;
}

.organizationchart-demo .p-organizationchart .node-headerC,
.organizationchart-demo .p-organizationchart .node-content2{
    padding: .5em .7rem;
}
.organizationchart-demo .p-organizationchart .node-header {
    background-color: #7B139B;
    color: #ffffff;
}

.organizationchart-demo .p-organizationchart .node-headerC{
    background-color: #828282;
    color: #ffffff;
}

.organizationchart-demo .p-organizationchart .node-content {
    text-align: center;
    border: 1px solid #7B139B;
}
.organizationchart-demo .p-organizationchart .node-content2{
    text-align: center;
    border: 1px solid #828282;
}

.organizationchart-demo .p-organizationchart .node-content img {
    border-radius: 50%;
}

.organizationchart-demo .p-organizationchart .node-content2 img{
    border-radius: 50%;
}

.organizationchart-demo .p-organizationchart .department-cfo {
    background-color: #7247bc;
    color: #ffffff;
}

.organizationchart-demo .p-organizationchart .department-coo {
    background-color: #a534b6;
    color: #ffffff;
}

.organizationchart-demo .p-organizationchart .department-cto {
    background-color: #e9286f;
    color: #ffffff;
}