@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");

* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Helvetica', sans-serif;
  }

  html {
    font-size: 14px;
    background-color: #f8f9fa;
  }
  
  .container {
    color: #444;
    text-align: center;
    margin-top: 1.8%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .subtittle{
    padding-left: 20%;
    padding-right: 20%;
    margin-bottom: 30px;
    margin-top: 15px;
    color: #495057;
  }

  .tittle{
    color: #444;
    text-align: unset;
    margin-bottom: 10px;
  }

  .tabla{
    background: var(--surface-e);
    padding: 2rem;
    -webkit-box-shadow: 0 3px 5px rgba(0,0,0,.02),0 0 2px rgba(0,0,0,.05),0 1px 4px rgba(0,0,0,.08);
    box-shadow:  0 3px 5px rgba(0,0,0,.02),0 0 2px rgba(0,0,0,.05),0 1px 4px rgba(0,0,0,.08);
    border-radius: 4px;
    margin-bottom: 2rem;
  }

.body {
    /* background-color: #ffffff; */
    font-weight: normal;
    color: #495057;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: .3em;
    
}

/* .card {
    margin-bottom: 2rem;
} */

 .icono{
    display: flex;
    align-items: center;
    height: 18px;
    width: 18px;
    
  }


.imageee{
  width: 155px;
  height: 60px;
}        

.p-splitter-panel-nested {
  display: block;
}

.space-divider{
  margin-top: 30px;
  margin-bottom: 30px;
}

.btnPositionSave{
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}

.text{
  color: #495057;
}

.btnSave{
  position: fixed;
  z-index: 1000;
}

.rowError {
  background-color: #ffcdd2 !important;
}