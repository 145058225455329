.header{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 1100;
  position: fixed;
  background: #ffffff;
  padding: 20px;
  width: calc(100% - 0%);
  top: 0;
  height: 75px;
    
  }
  
  .right_area{
    display: flex;
    align-items: center;
    justify-content: flex-end;


    
  }

  .left_area{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }

  .left_areaH3{
    display: flex;
    flex-wrap: wrap;
    color: #444;
    margin: 0;
    font-size: 22px;
    font-weight: 900;
  }
  
  .left_areaSpan{
    color: #7B139B;
    margin-left: 0px;
  }
  
  .logout_btn{
    padding: 12px;
    background: #7B139B;
    text-decoration: none;
    float: right;
    /* margin-top: 3px; */
    /* margin-right: 40px; */
    border-radius: 5px;
    color: #fff;
    transition: 0.5s;
    /* height: 25px; */
    /* width: 130px;*/
  }
  .logout_btn:hover{
    background: #7B139B;
  }

  .sidebar_btn {
    z-index: 1;
    color: #444;
    position: fixed;
    cursor: pointer;
    left: 300px;
    font-size: 20px;
    margin: 5px 0;
    transition: 0.5s;
    transition-property: color;
}



.sidebar{
    z-index: 1;
    top: 0px;
    background: #ffffff;
    margin-top: 70px;
    padding-top: 25px;
    position: fixed;
    left: 0;
    width: 260px;
    height: calc(100% - 7.5%);
    transition: 0.5s;
    transition-property: left;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid #dee2e6;
    /* box-shadow: 5px 3px 4px rgba(0,0,0,.25); */
  }

@media screen and (max-height: 840px){
  .sidebar{
    height: calc(100% - 9%);
  }
}

@media screen and (max-height: 735px){
  .sidebar{
    height: calc(100% - 10%);
  }
}

  
  .profile_info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .profile_image{
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-bottom: 10px;
  }
  
 .profile_infoH4{
    color: #444;
    margin-top: 0;
    margin-bottom: 15px;
  }
  .profile_infoH4_Mobile{
    color: #444;
    margin-top: 0;
    margin-left: 15px;
  }
  
  .sidebarA{
    color: #444;
    display: block;
    width: 100%;
    line-height: 47px;
    text-decoration: none;
    padding-left: 30px;
    box-sizing: border-box;
    transition: 0.5s;
    font-size: 16px;

  }
  
  .sidebarA:hover{
    background: #7B139B;
    color: #fff;
  }
  
  .sidebarI{
    padding-right: 10px;
  }

  .sidebarActive{
    left: -185px;
  }
 /*  #check:checked ~ .sidebar{
    left: -185px;
  }
  
  #check:checked ~ .sidebar a span{
    display: none;
  }
  
  #check:checked ~ .sidebar a{
    font-size: 20px;
    margin-left: 165px;
    width: 100%;
  } */
  
  .content{
    width: (100% - 250px);
    margin-top: 70px;
    padding: 20px;
    margin-left: 260px;
    background-position: center;
    background-size: cover;
    /* height: 100vh; */
    transition: 0.5s;
  }
  
  /* .contentActive{
    
    width: (100% - 250px);
    margin-top: 70px;
    padding: 20px;
    margin-left: 60px;
    background-position: center;
    background-size: cover;
    height: 100vh;
    transition: 0.5s;
  } */
  /* #check:checked ~ .content{
    margin-left: 60px;
  }
  
  #check:checked ~ .sidebar .profile_info{
    display: none;
  }
  
  #check{
    display: none;
  } */
  
  .mobile_nav{
    display: none;
  }
  
  /* .content .card p{
    background: transparent;
    padding: 15px;
    margin-bottom: 10px;
    font-size: 14px;
    opacity: 0.8;
  } */


  .BtnFaBars{
    border: hidden;
    background: transparent;
  }

  @media screen and (min-width: 991px){
    .header{
      box-shadow: 3px 2px 4px rgb(0 0 0 / 25%);
    }
  }
  @media screen and (max-width: 990px){
    

    .sidebar{
      display: none;
      
    }
  
    .sidebar_btn{
      display: none;
    }
  
    .content{
      margin-left: 0;
      margin-top: 0;
      padding: 10px 20px;
      transition: 0s;
    }
  
    /* #check:checked ~ .content{
      margin-left: 0;
    } */
  
    .mobile_nav{
      display: block;
      width: calc(100% - 0%);
    }
  
    .nav_bar{
      background: #ffffff;
      width: (100% - 0px);
      margin-top: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      box-shadow: 5px 3px 4px rgba(0,0,0,.25);
    }
  
    .nav_bar .mobile_profile_image{
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  
    .nav_bar .nav_btn{
      color: #444;
      font-size: 22px;
      cursor: pointer;
      transition: 0.5s;
      transition-property: color;
    }
  
    .nav_bar .nav_btn:hover{
      color: #444;
    }
  
    .mobile_nav_items{
      background: #ffffff;
      display: none;
    }
  
    .mobile_nav_itemsA{
      color: #444;
      display: block;
      text-align: center;
      letter-spacing: 1px;
      line-height: 60px;
      text-decoration: none;
      box-sizing: border-box;
      transition: 0.5s;
      
    }
  
    .mobile_nav_itemsA:hover{
      background: #7B139B;
      color: #fff;
    }
  
    .mobile_nav_itemsI{
      padding-right: 10px;
    }
  
    .active{
      display: block;
    }

    .p-slidemenu .p-menuitem  {
      display: flex;
      justify-content: center;
    }

    .p-hidden{
      display: none !important;
    }

    .p-slidemenu .p-slidemenu-backward{
      display: flex;
      justify-content: center;
    }


    .p-panelmenu .p-component{
      display: flex;
      justify-content: center;
    }
  
    .p-panelmenu .p-panelmenu-content {
      display: flex;
      justify-content: center;
    }
  }

/* @media screen and (max-height: 720px){
  .p-slidemenu .p-slidemenu-backward {
    margin-bottom: 440px !important;
  }
} */

@media screen and (max-width: 393px){

  .header{
    
    height: 80px;
  }

  .nav_bar{
    
    margin-top: 75px;
    
  }
}  

@media screen and (max-width: 451px){

  .header{
    /* justify-content: right; */
    height: 135px;
  }

  .nav_bar{
    
    margin-top: 130px;
    
  }
} 
@media screen and (max-width: 295px){

  .header{
    justify-content: right;
    height: 170px;
  }

  .nav_bar{
    
    margin-top: 165px;
    
  }
} 

.imgMenu{
  max-width: 173;
  max-height: 67px;
  margin-top: -20px;
  object-fit: contain;
  overflow: hidden;
}
.imgMenu2{
  
  max-height: 67px;
  margin-top: -21px;
  object-fit: contain;
}

.imgMenu3{
  
  position: absolute;
  top: -42px;
  height: 135px;
}

.imgMenu4{
  position: absolute;
  /* left: 7px; */
  max-height: 75px;
  top: 45px;
}

.require{
  color: red;
  
}


.Cierre{
  border: hidden;
  background: transparent;
  border-radius: 0px;
  padding-right: 49px;
}
.calendario{
  border: hidden;
  background: transparent;
  border-radius: 0px;
  padding-right: 86px;
}
.calendarioMobile{
  border: hidden;
  background: transparent;
  border-radius: 0px;
  display: block;
  text-align: center;
}

.seleccionar{
  padding-left: 50px;
  font-size: 15px;
  line-height: 37px;
}


.p-slidemenu{
  padding: 0.25rem 0 !important;
  background: #ffffff !important;
  color: #495057 !important;
  border: 0px !important;
  border-radius: 0px !important;
  padding-left: 10px !important;
  width: 100% !important;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
  margin-left: auto !important;
}

.p-panelmenu .p-panelmenu-header > a {
  border: 1px solid #ffffff !important;
}

.p-panelmenu .p-panelmenu-content {
  border: 1px solid #ffffff !important;
  background: #ffffff !important;
  color: #495057;
}

.p-panelmenu .p-panelmenu-header > a {
  padding: .75rem 1rem !important;
  font-weight: 100 !important;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  
  margin-left: 23px !important;
}

.p-panelmenu .p-panelmenu-header > a {
  background: #ffffff !important; 
}



