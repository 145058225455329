@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap);


.bodyLogin{
 /*  background-color: #ffffff; */
  font-weight: normal;
  color: #495057;
  padding: .3em;
  display: flex;
  justify-content: center;
}

.main{
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  max-width: 450px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* top: 1px; */
  margin-top: 40px;
  border-radius: 20px;
  background-color: #efefef;
}

.title {
  /* text-transform: uppercase; */
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 30px;
  color: #444;
}

.separator {
  width: 170px;
  height: 4px;
  background-color: #7B139B;
  margin: 24px;
}

.welcome-message {
  text-align: center;
  font-size: 1.1em;
  line-height: 28px;
  /* margin-bottom: 30px; */
  color: #696969;
  text-decoration: none;
  border: transparent;
}

.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;

}

.form-control {
  width: 100%;
  position: relative;
  margin-bottom: 24px;
}

.form-controlCAPTCHA {
  width: 250px;
  position: relative;
  /* margin-bottom: 24px; */
  margin-top: 5px;
}


.inputs{

  border: none;
  outline: none;
  border-radius: 30px;
  font-size: 1.1em;
  width: 100%;
  background: #e6e6e6;
  color: #333;
  letter-spacing: 0.5px;
  padding: 14px 64px;
}


.submit {

  border: none;
  outline: none;
  border-radius: 30px;
  font-size: 1.1em;

  color: #fff;
  padding: 14px 64px;
  margin: 32px auto;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  background-image: linear-gradient(to right, #8b33c5, #7B139B);
  cursor: pointer;
  transition: opacity 0.4s;
  margin-top: 10px;
}

.submit:hover {
  opacity: 0.9;
}

.submit2{
  border: none;
  outline: none;
  border-radius: 30px;
  font-size: 1.1em;

  color: #fff;
  padding: 14px 64px;
  margin: 32px auto;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  background-image: linear-gradient(to right, #495057, #495057);
  cursor: pointer;
  margin-top: 10px;
}


.icon{
  position: absolute;
  left: 32px;
  margin-top: 23px;
  transform: translateY(-50%);
  color: #888;
  transition: color   0.4s;
}

.inputs:focus ~ .icon{
  color: #7B139B;
}

/* ----  Responsiveness   ----  */
/* @media screen and (max-width: 780px) {

  .main {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .side {
      display: flex;
  }
}
*/

@media screen and (max-width: 290px) {

  .login-container{
    width: 220px ;
  }
  .form-controlCAPTCHA{
    width: 180px;
  }
} 



.mensajeError{
    color: #e21010;
    margin-left: 8px;
    margin-top: 3px;
    font-weight: 100 !important;
}

.aceptar{
  color: #f6f6f6;
  font-weight: bold;
  margin-left: 8px;
  background-color: transparent;
  cursor: pointer;
  border: 0px solid;
  text-transform: uppercase;
  letter-spacing: 2px;
}


.mensajeErrorLogin{
  color: #e21010;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
}

.inputsError{
  border: 2px solid #e21010;
  outline: none;
  border-radius: 30px;
  font-size: 1.1em;
  width: 100%;
  background: #e6e6e6;
  color: #333;
  letter-spacing: 0.5px;
  padding: 14px 64px;
}

.mensajeErrorButton{
  border: none;
  outline: none;
  border-radius: 30px;
  font-size: 1.1em;
  display: flex;
  justify-content: center; 
  align-items: center;

  color: #fff;
  padding: 5px 20px;
  margin: 32px auto;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  background-image: linear-gradient(to right, #8b33c5, #8b33c5);
  cursor: pointer;
  transition: opacity 0.4s;
  margin-top: 10px;
}






.ColoresLinexperts{
  color:#7B139B;
  color:#DFAA11;
  color:#ED122B;
  color:#B8DA0D;
  color:#46225A;
  color:#708471;
  color:#FAFAFA;
  color:#000000;
}
.header{
  display: flex;
  flex-wrap: wrap;
  align-items: flex-start;
  justify-content: space-between;
  z-index: 1100;
  position: fixed;
  background: #ffffff;
  padding: 20px;
  width: calc(100% - 0%);
  top: 0;
  height: 75px;
    
  }
  
  .right_area{
    display: flex;
    align-items: center;
    justify-content: flex-end;


    
  }

  .left_area{
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-start;
  }

  .left_areaH3{
    display: flex;
    flex-wrap: wrap;
    color: #444;
    margin: 0;
    font-size: 22px;
    font-weight: 900;
  }
  
  .left_areaSpan{
    color: #7B139B;
    margin-left: 0px;
  }
  
  .logout_btn{
    padding: 12px;
    background: #7B139B;
    text-decoration: none;
    float: right;
    /* margin-top: 3px; */
    /* margin-right: 40px; */
    border-radius: 5px;
    color: #fff;
    transition: 0.5s;
    /* height: 25px; */
    /* width: 130px;*/
  }
  .logout_btn:hover{
    background: #7B139B;
  }

  .sidebar_btn {
    z-index: 1;
    color: #444;
    position: fixed;
    cursor: pointer;
    left: 300px;
    font-size: 20px;
    margin: 5px 0;
    transition: 0.5s;
    transition-property: color;
}



.sidebar{
    z-index: 1;
    top: 0px;
    background: #ffffff;
    margin-top: 70px;
    padding-top: 25px;
    position: fixed;
    left: 0;
    width: 260px;
    height: calc(100% - 7.5%);
    transition: 0.5s;
    transition-property: left;
    overflow-x: hidden;
    overflow-y: auto;
    border-right: 1px solid #dee2e6;
    /* box-shadow: 5px 3px 4px rgba(0,0,0,.25); */
  }

@media screen and (max-height: 840px){
  .sidebar{
    height: calc(100% - 9%);
  }
}

@media screen and (max-height: 735px){
  .sidebar{
    height: calc(100% - 10%);
  }
}

  
  .profile_info{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  .profile_image{
    width: 100px;
    height: 100px;
    border-radius: 100px;
    margin-bottom: 10px;
  }
  
 .profile_infoH4{
    color: #444;
    margin-top: 0;
    margin-bottom: 15px;
  }
  .profile_infoH4_Mobile{
    color: #444;
    margin-top: 0;
    margin-left: 15px;
  }
  
  .sidebarA{
    color: #444;
    display: block;
    width: 100%;
    line-height: 47px;
    text-decoration: none;
    padding-left: 30px;
    box-sizing: border-box;
    transition: 0.5s;
    font-size: 16px;

  }
  
  .sidebarA:hover{
    background: #7B139B;
    color: #fff;
  }
  
  .sidebarI{
    padding-right: 10px;
  }

  .sidebarActive{
    left: -185px;
  }
 /*  #check:checked ~ .sidebar{
    left: -185px;
  }
  
  #check:checked ~ .sidebar a span{
    display: none;
  }
  
  #check:checked ~ .sidebar a{
    font-size: 20px;
    margin-left: 165px;
    width: 100%;
  } */
  
  .content{
    width: (100% - 250px);
    margin-top: 70px;
    padding: 20px;
    margin-left: 260px;
    background-position: center;
    background-size: cover;
    /* height: 100vh; */
    transition: 0.5s;
  }
  
  /* .contentActive{
    
    width: (100% - 250px);
    margin-top: 70px;
    padding: 20px;
    margin-left: 60px;
    background-position: center;
    background-size: cover;
    height: 100vh;
    transition: 0.5s;
  } */
  /* #check:checked ~ .content{
    margin-left: 60px;
  }
  
  #check:checked ~ .sidebar .profile_info{
    display: none;
  }
  
  #check{
    display: none;
  } */
  
  .mobile_nav{
    display: none;
  }
  
  /* .content .card p{
    background: transparent;
    padding: 15px;
    margin-bottom: 10px;
    font-size: 14px;
    opacity: 0.8;
  } */


  .BtnFaBars{
    border: hidden;
    background: transparent;
  }

  @media screen and (min-width: 991px){
    .header{
      box-shadow: 3px 2px 4px rgb(0 0 0 / 25%);
    }
  }
  @media screen and (max-width: 990px){
    

    .sidebar{
      display: none;
      
    }
  
    .sidebar_btn{
      display: none;
    }
  
    .content{
      margin-left: 0;
      margin-top: 0;
      padding: 10px 20px;
      transition: 0s;
    }
  
    /* #check:checked ~ .content{
      margin-left: 0;
    } */
  
    .mobile_nav{
      display: block;
      width: calc(100% - 0%);
    }
  
    .nav_bar{
      background: #ffffff;
      width: (100% - 0px);
      margin-top: 70px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 20px;
      box-shadow: 5px 3px 4px rgba(0,0,0,.25);
    }
  
    .nav_bar .mobile_profile_image{
      width: 50px;
      height: 50px;
      border-radius: 50%;
    }
  
    .nav_bar .nav_btn{
      color: #444;
      font-size: 22px;
      cursor: pointer;
      transition: 0.5s;
      transition-property: color;
    }
  
    .nav_bar .nav_btn:hover{
      color: #444;
    }
  
    .mobile_nav_items{
      background: #ffffff;
      display: none;
    }
  
    .mobile_nav_itemsA{
      color: #444;
      display: block;
      text-align: center;
      letter-spacing: 1px;
      line-height: 60px;
      text-decoration: none;
      box-sizing: border-box;
      transition: 0.5s;
      
    }
  
    .mobile_nav_itemsA:hover{
      background: #7B139B;
      color: #fff;
    }
  
    .mobile_nav_itemsI{
      padding-right: 10px;
    }
  
    .active{
      display: block;
    }

    .p-slidemenu .p-menuitem  {
      display: flex;
      justify-content: center;
    }

    .p-hidden{
      display: none !important;
    }

    .p-slidemenu .p-slidemenu-backward{
      display: flex;
      justify-content: center;
    }


    .p-panelmenu .p-component{
      display: flex;
      justify-content: center;
    }
  
    .p-panelmenu .p-panelmenu-content {
      display: flex;
      justify-content: center;
    }
  }

/* @media screen and (max-height: 720px){
  .p-slidemenu .p-slidemenu-backward {
    margin-bottom: 440px !important;
  }
} */

@media screen and (max-width: 393px){

  .header{
    
    height: 80px;
  }

  .nav_bar{
    
    margin-top: 75px;
    
  }
}  

@media screen and (max-width: 451px){

  .header{
    /* justify-content: right; */
    height: 135px;
  }

  .nav_bar{
    
    margin-top: 130px;
    
  }
} 
@media screen and (max-width: 295px){

  .header{
    justify-content: right;
    height: 170px;
  }

  .nav_bar{
    
    margin-top: 165px;
    
  }
} 

.imgMenu{
  max-width: 173;
  max-height: 67px;
  margin-top: -20px;
  object-fit: contain;
  overflow: hidden;
}
.imgMenu2{
  
  max-height: 67px;
  margin-top: -21px;
  object-fit: contain;
}

.imgMenu3{
  
  position: absolute;
  top: -42px;
  height: 135px;
}

.imgMenu4{
  position: absolute;
  /* left: 7px; */
  max-height: 75px;
  top: 45px;
}

.require{
  color: red;
  
}


.Cierre{
  border: hidden;
  background: transparent;
  border-radius: 0px;
  padding-right: 49px;
}
.calendario{
  border: hidden;
  background: transparent;
  border-radius: 0px;
  padding-right: 86px;
}
.calendarioMobile{
  border: hidden;
  background: transparent;
  border-radius: 0px;
  display: block;
  text-align: center;
}

.seleccionar{
  padding-left: 50px;
  font-size: 15px;
  line-height: 37px;
}


.p-slidemenu{
  padding: 0.25rem 0 !important;
  background: #ffffff !important;
  color: #495057 !important;
  border: 0px !important;
  border-radius: 0px !important;
  padding-left: 10px !important;
  width: 100% !important;
}

.p-slidemenu .p-menuitem-link .p-submenu-icon {
  margin-left: auto !important;
}

.p-panelmenu .p-panelmenu-header > a {
  border: 1px solid #ffffff !important;
}

.p-panelmenu .p-panelmenu-content {
  border: 1px solid #ffffff !important;
  background: #ffffff !important;
  color: #495057;
}

.p-panelmenu .p-panelmenu-header > a {
  padding: .75rem 1rem !important;
  font-weight: 100 !important;
}
.p-panelmenu .p-panelmenu-content .p-menuitem .p-menuitem-link {
  
  margin-left: 23px !important;
}

.p-panelmenu .p-panelmenu-header > a {
  background: #ffffff !important; 
}




.Tittle{
    color: #444;
    margin: 0em 1em 1em;
    text-align: center;
    font-size: 17px;
}



.contBtn{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.btn{
    text-align: center;
    background-color: #fff;
    border: 2px solid #7B139B;
    
    border-radius: 5px;
    color: #444;
    cursor: pointer;
    transition: 0.5s;
    padding: 4px 20px;
    margin-left: 10px;
    font-size: 16px;
    text-decoration: none;
   
}

.btn:hover{
    
    background-color: #7B139B;
    border: 2px solid #7B139B;
    
    color: #fff;
    cursor: pointer;
    transition: 0.5s;
    
}
.Formulario{
    flex-direction: column;
    padding: 0rem 2rem;
    transition: all 0.2s 0.7s;
    overflow: hidden;
    
}




.btnEliminar{
    text-align: center;
    width: 150px;
    background-color: #fff;
    border: 2px solid #ED122B;
    height: 35px;
    border-radius: 5px;
    color: #444;
    cursor: pointer;
    transition: 0.5s;
    padding: 4px 20px;
    margin-left: 10px;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
}
.btnEliminar:hover{
    width: 150px;
    background-color: #ED122B;
    border: 2px solid #ED122B;
    height: 35px;
    color: #fff;
    cursor: pointer;
    transition: 0.5s;
    padding: 4px 20px;
    margin-left: 10px;
}

.tablePdf{
    border-collapse: collapse; 
    border: none;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

.trPdf1{
    height: 25.6pt;
}

.trPdf2{
    height: 39.75pt;
}

.tdPdf1{
    width: 147.9pt; 
    border: solid 1.5pt; 
    border-right: solid 1.0pt; 
    padding: 0cm 5.4pt 0cm 5.4pt; 
    height: 25.6pt;
}

.tdPdf2{
    width: 210.65pt; 
    border-top: solid 1.5pt; 
    border-left: none; 
    border-bottom: solid 1.0pt; 
    border-right: solid 1.0pt; 
    padding: 0cm 5.4pt 0cm 5.4pt; 
    height: 25.6pt;
}
.tdPdf3{
    width: 210.65pt; 
    border-top: none;
    border-left: none; 
    border-bottom: solid 1.5pt; 
    border-right: solid 1.0pt; 
    padding: 0cm 5.4pt 0cm 5.4pt; 
    height: 39.75pt;
}
.pPdf{
    text-align: center
}
.dialog-demo .p-button {
    margin: 0 .5rem 0 0;
    
}

.dialog-demo p {
    margin: 0;
    line-height: 1.5;
}

.dialog-demo .confirmation-content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.confirmation-content-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialog-demo .p-dialog .p-button {
    min-width: 6rem;
}
   
.modal{
    width: 70vw;
}

@media screen and (max-width: 700px){
    .modal{
        width: 90vw;
    }

}


.modalConfirm{
    width: 450px;
}

@media screen and (max-width: 700px){
    .modalConfirm{
        width: 300px;
    }

}

.labels{
    color: #444;
    margin-top: 6px;
    display: flex !important;
    align-items: center !important;
}

.labelsConfirm{
    color: #444;
    margin-top: 6px;
    float: left;
}

/* .contentModalConfirm{
    background-color: red;
    width: 350px;
} */

/* .warning{
    
    position: absolute;
} */


.recordatorio{
    color: red;
}

.p-autocomplete-panel .p-autocomplete-items {
    padding: 0.5rem 0;
    max-height: 130px;
}

.p-dialog .p-dialog-content {
    overflow-y: auto;
    overflow: visible;
}

@media screen and (max-height: 1000px){
    .p-dialog .p-dialog-content {
        overflow-y: auto;
        overflow: auto;
    }

}


.auto .p-button {
   border-color: transparent;
}
.auto .p-autocomplete-dropdown {
    background-color: transparent;
    border-top-color: #b8bec4;
    border-bottom-color: #b8bec4;
    border-left-color: 1px solid transparent;
    border-right-color: #b8bec4;
    color: #727a82;
}

.auto .p-button:enabled:hover {
    background-color: transparent;
    border-top-color: #b8bec4;
    border-bottom-color: #b8bec4;
    border-left-color: #b8bec4;
    border-right-color: #b8bec4;
    color: #727a82;
}

.auto .p-autocomplete-input {
    background-color: transparent;
    border-top-color: #b8bec4;
    border-bottom-color: #b8bec4;
    border-left-color: #b8bec4;
    border-right-color: 1px solid transparent;
}

.p-rowgroup-header{
    background-color: #f8f9fa !important;
}

.center{
    display: flex;
    align-items: center;
}

.p-inputtext {
    border: 1px solid #b8bec4;
}

.p-multiselect {
    border: 1px solid #b8bec4;
}

.p-dropdown {
    border: 1px solid #b8bec4;
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #b8bec4;
}


  
.mainbox {
    background-color: #f8f9fa;
    margin: auto;
    height: 600px;
    width: 600px;
    position: relative;
}
.err {
    color: #444444;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 20%;
    top: 8%;
}
  
.far {
    position: absolute;
    font-size: 8.5rem;
    left: 42%;
    top: 15%;
    color: #444444;
}
  
.err2 {
    color: #444444;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 68%;
    top: 8%;
}
  
.msg {
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.6rem;
    position:absolute;
    left: 16%;
    top: 45%;
    width: 75%;
}
  
.a {
    text-decoration: none;
    color: #444444;
}
  
.a:hover {
    text-decoration: underline;
}
#cambiar-fecha {
    margin-top: 3px;
    margin-left: 10px;

}

#vincular-fecha {
    margin-top: 3px;
    margin-left: 10px;
}

#boton-official {
    top: 28px;
    right: 5px;
    margin: 2px;
    padding: 7px;
}

#habilitar-campo{
    top: 28px;
    right: 13px;
    margin: 2px;
    padding: 7px;
}
#boton-coordinator {
    top: 28px;
    right: 13px;
    margin: 2px;
    padding: 7px;
}


.tabview-demo .tabview-header-icon i,
.tabview-demo .tabview-header-icon span {
    vertical-align: middle;
}

.tabview-demo .tabview-header-icon span {
    margin: 0 .5rem;
}

.tabview-demo .p-tabview p {
    line-height: 1.5;
    margin: 0;
}
.datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 960px) {
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}


.datatable-scroll-demo .loading-text {
    display: block;
    background-color: #f1f1f1;
    min-height: 19px;
    animation: pulse 1s infinite ease-in-out;
    text-indent: -99999px;
    overflow: hidden;
}

.calendar{
    text-align: center;
}
.p-column-filter {
    width: 100%;
    
}
.p-dropdown-panel .p-dropdown-items {
    padding: 0.5rem 0;
    z-index: 999;
    max-height: 150px;
    max-width: 400px;
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    left: 0px;
    /* box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%); */
}

@media screen and (max-width: 485px) {
    .p-dropdown-panel .p-dropdown-items {
       
        max-width: 300px;
        
    }
}
@media screen and (max-width: 380px) {
    .p-dropdown-panel .p-dropdown-items {
       
        max-width: 200px;
        
    }
}
@media screen and (max-width: 265px) {
    .p-dropdown-panel .p-dropdown-items {
       
        max-width: 150px;
        
    }
}


.p-datatable-scrollable-header{
    overflow: visible;
}

.columna{
    overflow: hidden;
}


.p-datepicker-calendar {

    text-align: center;
}

.btnAdd{
    background-color: #77b83e !important;
    margin: 0;
    display: inline-flex;
    align-items: center;
    border: 1px solid !important;
    outline: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: 0.5s;
    padding: 7px 20px;
    font-size: 14px;
    text-decoration: none;
    /* width: 100%; */
}
.Add{
    
    margin-right: 5px;
    font-size: 12px;
}

.delete {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.btnEditar{
    color: #444;
    background: transparent;
    border: 1px solid transparent;
    margin-left: 5px;
}

.btnEditar:hover {
    color: #444;
    background: transparent;
    border: 1px solid transparent;
}

.headerTable{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    
}

.btnTrash{
    margin-top: 5px;
    margin-left: 5px;
}

.btnRegresar{
    color: #7B139B;
    background-color: transparent;
    border-color: transparent;
    
}

.breadcrumbs {
    padding: 10px;
    margin-left: 20px;
}

.breadcrumbsItem{
    display: inline-block;
}

.breadcrumbsItem:not(:last-of-type)::after{
    content: '/';
    font-size: 15px;
    margin: 0 5px;
    color: #cccccc;
    float: right;
}

.breadcrubsLink{
    text-decoration: none;
    
    color: #999999;
}

.breadcrubsLink:hover{
    font-weight: 600;
    color: #7B139B;
}
.disabled{
    color: #999999;
}

.Botones{
    display: flex;
}

.Acciones{
    text-align: right;
}
         

.mensajeCofirmar{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContainerBtnRegresar{
    display: grid;
    justify-items: center;
    margin-top: 16px;
}

.BtnRegresar{
    padding: 12px;
    background: #7B139B;
    text-decoration: none;
    float: right;
    border-radius: 5px;
    color: #fff;
    transition: 0.5s;
    
}
.regresar{
    text-decoration: none;
}
.DatosFooter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* height: 9em;
    margin-top: 2em; */
}

.ExportTittle{
    margin-bottom: 10px;
}


.fila{
    text-align: left;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 300;
}

/* .p-datatable table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    overflow: visible;
}
 */

 .fechaFlilter .p-datepicker{
    /* background-color: red; */
    overflow: scroll;
    max-height: 190px;
    
 }

 /* .p-multiselect .p-multiselect-panel {
    max-width: 100%;
} */


.datatable-rowgroup-demo .p-rowgroup-footer td {
    font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header span {
    font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header .p-row-toggler {
    vertical-align: middle;
    margin-right: .25rem;
}

.buttonExportHistorico {
    display: flex;
    justify-content: flex-end;

}

.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: transparent;
    color: #495057;
}

.tittleChart {
    color: #444;
    text-align: center;
    font-size: 14px;
    padding: 1em;
}

.limpiar {
    display: flex;
    justify-content: center;
    margin-left: 10px;
}


.p-slider {
    background: #93979c !important;
}

.separador-vertical {
    width: 60px;
    height: 4px;
    background-color:#959596;
    margin: 24px;
    transform: rotate(90deg);
}

.p-radiobutton .p-radiobutton-box {
    border: 2px solid #b1b1b1 !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #2196F3 !important;
    background: #2196F3;
}

.text-center{
    text-align: center;
}

.center-rd{
    justify-content: center;
}

.btn-report{
    display: flex;
    justify-content: flex-end
}
.Btn{
   top: 23px;
   right: 10px;  
}
.tabview-demo .tabview-custom i, .tabview-demo .tabview-custom span {
    vertical-align: middle;
}

.tabview-demo .tabview-custom span {
    margin: 0 .5rem;
}

.tabview-demo .p-button {
    margin-right: .25rem;
}

.tabview-demo .p-tabview p {
    line-height: 1.5;
    margin: 0;
}


 .p-datepicker table td {
    padding: 0.3rem;
}
.p-datepicker .p-datepicker-header {
    padding: 0.3rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #dee2e6;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-datepicker table td > span {
    border-radius: 50%;
    transition: box-shadow 0.2s;
    border: 1px solid transparent;
    width: 1.7rem; 
    height: 1.7rem;
    
}

.p-datepicker .p-datepicker-buttonbar {
    padding: 0;
    border-top: 1px solid #dee2e6;
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 0.8rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    transition: box-shadow 0.2s;
}

.p-inputtextarea{
    height: 33px;
}

.p-datepickerCalendar table td > span.p-highlight {
    color: #495057;
    background: #b662c0;
}

.p-datepicker table td > span.p-highlight {
    color: #495057;
    background: #b662c0;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #495057;
    background: #b662c0;
}   
/* Día festivo */
.holiday {
    background-color: #fff; 
    color: #495057; 
    border-radius: 50%; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 1.5rem; 
    height: 1.5rem;
    border: 0.2rem solid #b662c0; 
    font-size: 1.1rem; 
    line-height: 1; 
    margin: auto; 
}

/* Día festivo seleccionado */
.holiday-selected {
    background-color: #93a0ad; 
    color: #fff; 
    border-radius: 50%; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 2rem; 
    border: 0.2rem solid #7e8a96; 
    font-size: 1.1rem; 
    line-height: 1; 
    margin: auto; 
}




.BtnNotas{
    bottom: 5px;
}
.BtnDuplicar{
    bottom: 5px;
    height: 33px;
    margin-top: 3.5px;
  
}
.p-field-checkbox > label, .p-field-radiobutton > label {
    margin-right: 0.5rem !important;
}
/* .p-jc-center {
    justify-content: space-around;
} */

.p-d-flex {
    display: flex !important;
    flex-wrap: wrap !important;
}
* {
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    font-family: 'Helvetica', sans-serif;
  }

  html {
    font-size: 14px;
    background-color: #f8f9fa;
  }
  
  .container {
    color: #444;
    text-align: center;
    margin-top: 1.8%;
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
  }

  .subtittle{
    padding-left: 20%;
    padding-right: 20%;
    margin-bottom: 30px;
    margin-top: 15px;
    color: #495057;
  }

  .tittle{
    color: #444;
    text-align: unset;
    margin-bottom: 10px;
  }

  .tabla{
    background: var(--surface-e);
    padding: 2rem;
    box-shadow:  0 3px 5px rgba(0,0,0,.02),0 0 2px rgba(0,0,0,.05),0 1px 4px rgba(0,0,0,.08);
    border-radius: 4px;
    margin-bottom: 2rem;
  }

.body {
    /* background-color: #ffffff; */
    font-weight: normal;
    color: #495057;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    padding: .3em;
    
}

/* .card {
    margin-bottom: 2rem;
} */

 .icono{
    display: flex;
    align-items: center;
    height: 18px;
    width: 18px;
    
  }


.imageee{
  width: 155px;
  height: 60px;
}        

.p-splitter-panel-nested {
  display: block;
}

.space-divider{
  margin-top: 30px;
  margin-bottom: 30px;
}

.btnPositionSave{
  display: flex;
  justify-content: flex-end;
  padding-top: 1rem;
}

.text{
  color: #495057;
}

.btnSave{
  position: fixed;
  z-index: 1000;
}

.rowError {
  background-color: #ffcdd2 !important;
}
.recuperar {
    background: var(--surface-e);
    padding: 2rem;
    box-shadow: 0px 0px 1px -1px rgb(247 247 247 / 99%), 0px 0px 0px 2px rgb(0 0 0 / 11%), 0 1px 2px 1px rgb(8 8 8 / 0%);
    border-radius: 4px;
    justify-content: center;
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
    margin: 8%;
    
}

.img{
    display: block;
    margin: auto;
    object-fit: contain;
    height: 80px;
}

.toast-demo button {
    min-width: 10rem;
    margin-right: .5rem;
}

@media screen and (max-width: 960px) {
    .toast-demo button {
        width: 100%;
        margin-bottom: .5rem;
    }
}
                
.organizationchart-demo .card {
    overflow-x: auto;
}

.organizationchart-demo .p-organizationchart .p-person {
    padding: 2px;
    border: 0 none;
    background-color: transparent;

}


.labelsorganigrama {
    font-weight: 600;
    top: -25px;
    position: relative;
    left: 50px;
}

.panel {
    background-color: #495ebb;
}

.organizationchart-demo .p-organizationchart .node-header,
.organizationchart-demo .p-organizationchart .node-content {
    padding: .5em .7rem;
}

.organizationchart-demo .p-organizationchart .node-headerC,
.organizationchart-demo .p-organizationchart .node-content2{
    padding: .5em .7rem;
}
.organizationchart-demo .p-organizationchart .node-header {
    background-color: #7B139B;
    color: #ffffff;
}

.organizationchart-demo .p-organizationchart .node-headerC{
    background-color: #828282;
    color: #ffffff;
}

.organizationchart-demo .p-organizationchart .node-content {
    text-align: center;
    border: 1px solid #7B139B;
}
.organizationchart-demo .p-organizationchart .node-content2{
    text-align: center;
    border: 1px solid #828282;
}

.organizationchart-demo .p-organizationchart .node-content img {
    border-radius: 50%;
}

.organizationchart-demo .p-organizationchart .node-content2 img{
    border-radius: 50%;
}

.organizationchart-demo .p-organizationchart .department-cfo {
    background-color: #7247bc;
    color: #ffffff;
}

.organizationchart-demo .p-organizationchart .department-coo {
    background-color: #a534b6;
    color: #ffffff;
}

.organizationchart-demo .p-organizationchart .department-cto {
    background-color: #e9286f;
    color: #ffffff;
}
