
.bodyLogin{
 /*  background-color: #ffffff; */
  font-weight: normal;
  color: #495057;
  padding: .3em;
  display: flex;
  justify-content: center;
}

.main{
  display: flex;
  justify-content: center;
  align-items: center;
}

.login-container {
  max-width: 450px;
  padding: 24px;
  display: flex;
  flex-direction: column;
  align-items: center;
  /* top: 1px; */
  margin-top: 40px;
  border-radius: 20px;
  background-color: #efefef;
}

.title {
  /* text-transform: uppercase; */
  font-size: 2em;
  font-weight: bold;
  text-align: center;
  letter-spacing: 1px;
  margin-bottom: 30px;
  color: #444;
}

.separator {
  width: 170px;
  height: 4px;
  background-color: #7B139B;
  margin: 24px;
}

.welcome-message {
  text-align: center;
  font-size: 1.1em;
  line-height: 28px;
  /* margin-bottom: 30px; */
  color: #696969;
  text-decoration: none;
  border: transparent;
}

.login-form {
  width: 100%;
  display: flex;
  flex-direction: column;

}

.form-control {
  width: 100%;
  position: relative;
  margin-bottom: 24px;
}

.form-controlCAPTCHA {
  width: 250px;
  position: relative;
  /* margin-bottom: 24px; */
  margin-top: 5px;
}


.inputs{

  border: none;
  outline: none;
  border-radius: 30px;
  font-size: 1.1em;
  width: 100%;
  background: #e6e6e6;
  color: #333;
  letter-spacing: 0.5px;
  padding: 14px 64px;
}


.submit {

  border: none;
  outline: none;
  border-radius: 30px;
  font-size: 1.1em;

  color: #fff;
  padding: 14px 64px;
  margin: 32px auto;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  background-image: linear-gradient(to right, #8b33c5, #7B139B);
  cursor: pointer;
  transition: opacity 0.4s;
  margin-top: 10px;
}

.submit:hover {
  opacity: 0.9;
}

.submit2{
  border: none;
  outline: none;
  border-radius: 30px;
  font-size: 1.1em;

  color: #fff;
  padding: 14px 64px;
  margin: 32px auto;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  background-image: linear-gradient(to right, #495057, #495057);
  cursor: pointer;
  margin-top: 10px;
}


.icon{
  position: absolute;
  left: 32px;
  margin-top: 23px;
  transform: translateY(-50%);
  color: #888;
  transition: color   0.4s;
}

.inputs:focus ~ .icon{
  color: #7B139B;
}

/* ----  Responsiveness   ----  */
/* @media screen and (max-width: 780px) {

  .main {
      display: flex;
      justify-content: center;
      align-items: center;
  }

  .side {
      display: flex;
  }
}
*/

@media screen and (max-width: 290px) {

  .login-container{
    width: 220px ;
  }
  .form-controlCAPTCHA{
    width: 180px;
  }
} 



.mensajeError{
    color: #e21010;
    margin-left: 8px;
    margin-top: 3px;
    font-weight: 100 !important;
}

.aceptar{
  color: #f6f6f6;
  font-weight: bold;
  margin-left: 8px;
  background-color: transparent;
  cursor: pointer;
  border: 0px solid;
  text-transform: uppercase;
  letter-spacing: 2px;
}


.mensajeErrorLogin{
  color: #e21010;
  display: flex;
  justify-content: center;
  text-align: center;
  margin-bottom: 10px;
}

.inputsError{
  border: 2px solid #e21010;
  outline: none;
  border-radius: 30px;
  font-size: 1.1em;
  width: 100%;
  background: #e6e6e6;
  color: #333;
  letter-spacing: 0.5px;
  padding: 14px 64px;
}

.mensajeErrorButton{
  border: none;
  outline: none;
  border-radius: 30px;
  font-size: 1.1em;
  display: flex;
  justify-content: center; 
  align-items: center;

  color: #fff;
  padding: 5px 20px;
  margin: 32px auto;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-weight: bold;
  background-image: linear-gradient(to right, #8b33c5, #8b33c5);
  cursor: pointer;
  transition: opacity 0.4s;
  margin-top: 10px;
}






.ColoresLinexperts{
  color:#7B139B;
  color:#DFAA11;
  color:#ED122B;
  color:#B8DA0D;
  color:#46225A;
  color:#708471;
  color:#FAFAFA;
  color:#000000;
}