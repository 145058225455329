.Tittle{
    color: #444;
    margin: 0em 1em 1em;
    text-align: center;
    font-size: 17px;
}



.contBtn{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.btn{
    text-align: center;
    background-color: #fff;
    border: 2px solid #7B139B;
    
    border-radius: 5px;
    color: #444;
    cursor: pointer;
    transition: 0.5s;
    padding: 4px 20px;
    margin-left: 10px;
    font-size: 16px;
    text-decoration: none;
   
}

.btn:hover{
    
    background-color: #7B139B;
    border: 2px solid #7B139B;
    
    color: #fff;
    cursor: pointer;
    transition: 0.5s;
    
}
.Formulario{
    flex-direction: column;
    padding: 0rem 2rem;
    transition: all 0.2s 0.7s;
    overflow: hidden;
    
}




.btnEliminar{
    text-align: center;
    width: 150px;
    background-color: #fff;
    border: 2px solid #ED122B;
    height: 35px;
    border-radius: 5px;
    color: #444;
    cursor: pointer;
    transition: 0.5s;
    padding: 4px 20px;
    margin-left: 10px;
    font-size: 16px;
    font-family: "Poppins", sans-serif;
    text-decoration: none;
}
.btnEliminar:hover{
    width: 150px;
    background-color: #ED122B;
    border: 2px solid #ED122B;
    height: 35px;
    color: #fff;
    cursor: pointer;
    transition: 0.5s;
    padding: 4px 20px;
    margin-left: 10px;
}

.tablePdf{
    border-collapse: collapse; 
    border: none;
    width: 70%;
    margin-left: 15%;
    margin-right: 15%;
}

.trPdf1{
    height: 25.6pt;
}

.trPdf2{
    height: 39.75pt;
}

.tdPdf1{
    width: 147.9pt; 
    border: solid 1.5pt; 
    border-right: solid 1.0pt; 
    padding: 0cm 5.4pt 0cm 5.4pt; 
    height: 25.6pt;
}

.tdPdf2{
    width: 210.65pt; 
    border-top: solid 1.5pt; 
    border-left: none; 
    border-bottom: solid 1.0pt; 
    border-right: solid 1.0pt; 
    padding: 0cm 5.4pt 0cm 5.4pt; 
    height: 25.6pt;
}
.tdPdf3{
    width: 210.65pt; 
    border-top: none;
    border-left: none; 
    border-bottom: solid 1.5pt; 
    border-right: solid 1.0pt; 
    padding: 0cm 5.4pt 0cm 5.4pt; 
    height: 39.75pt;
}
.pPdf{
    text-align: center
}