@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@200;300;400;500;600;700;800&display=swap");



.recuperar {
    background: var(--surface-e);
    padding: 2rem;
    box-shadow: 0px 0px 1px -1px rgb(247 247 247 / 99%), 0px 0px 0px 2px rgb(0 0 0 / 11%), 0 1px 2px 1px rgb(8 8 8 / 0%);
    border-radius: 4px;
    justify-content: center;
    display: flex;
    flex-flow: column;
    flex-wrap: nowrap;
    margin: 8%;
    
}

.img{
    display: block;
    margin: auto;
    object-fit: contain;
    height: 80px;
}
