#cambiar-fecha {
    margin-top: 3px;
    margin-left: 10px;

}

#vincular-fecha {
    margin-top: 3px;
    margin-left: 10px;
}

#boton-official {
    top: 28px;
    right: 5px;
    margin: 2px;
    padding: 7px;
}

#habilitar-campo{
    top: 28px;
    right: 13px;
    margin: 2px;
    padding: 7px;
}
#boton-coordinator {
    top: 28px;
    right: 13px;
    margin: 2px;
    padding: 7px;
}


.tabview-demo .tabview-header-icon i,
.tabview-demo .tabview-header-icon span {
    vertical-align: middle;
}

.tabview-demo .tabview-header-icon span {
    margin: 0 .5rem;
}

.tabview-demo .p-tabview p {
    line-height: 1.5;
    margin: 0;
}