
  
.mainbox {
    background-color: #f8f9fa;
    margin: auto;
    height: 600px;
    width: 600px;
    position: relative;
}
.err {
    color: #444444;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 20%;
    top: 8%;
}
  
.far {
    position: absolute;
    font-size: 8.5rem;
    left: 42%;
    top: 15%;
    color: #444444;
}
  
.err2 {
    color: #444444;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 11rem;
    position:absolute;
    left: 68%;
    top: 8%;
}
  
.msg {
    text-align: center;
    font-family: 'Nunito Sans', sans-serif;
    font-size: 1.6rem;
    position:absolute;
    left: 16%;
    top: 45%;
    width: 75%;
}
  
.a {
    text-decoration: none;
    color: #444444;
}
  
.a:hover {
    text-decoration: underline;
}