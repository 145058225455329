.dialog-demo .p-button {
    margin: 0 .5rem 0 0;
    
}

.dialog-demo p {
    margin: 0;
    line-height: 1.5;
}

.dialog-demo .confirmation-content {
    display: inline-flex;
    align-items: center;
    justify-content: center;
}
.confirmation-content-modal {
    display: flex;
    align-items: center;
    justify-content: center;
}

.dialog-demo .p-dialog .p-button {
    min-width: 6rem;
}
   
.modal{
    width: 70vw;
}

@media screen and (max-width: 700px){
    .modal{
        width: 90vw;
    }

}


.modalConfirm{
    width: 450px;
}

@media screen and (max-width: 700px){
    .modalConfirm{
        width: 300px;
    }

}

.labels{
    color: #444;
    margin-top: 6px;
    display: flex !important;
    align-items: center !important;
}

.labelsConfirm{
    color: #444;
    margin-top: 6px;
    float: left;
}

/* .contentModalConfirm{
    background-color: red;
    width: 350px;
} */

/* .warning{
    
    position: absolute;
} */


.recordatorio{
    color: red;
}

.p-autocomplete-panel .p-autocomplete-items {
    padding: 0.5rem 0;
    max-height: 130px;
}

.p-dialog .p-dialog-content {
    overflow-y: auto;
    overflow: visible;
}

@media screen and (max-height: 1000px){
    .p-dialog .p-dialog-content {
        overflow-y: auto;
        overflow: auto;
    }

}


.auto .p-button {
   border-color: transparent;
}
.auto .p-autocomplete-dropdown {
    background-color: transparent;
    border-top-color: #b8bec4;
    border-bottom-color: #b8bec4;
    border-left-color: 1px solid transparent;
    border-right-color: #b8bec4;
    color: #727a82;
}

.auto .p-button:enabled:hover {
    background-color: transparent;
    border-top-color: #b8bec4;
    border-bottom-color: #b8bec4;
    border-left-color: #b8bec4;
    border-right-color: #b8bec4;
    color: #727a82;
}

.auto .p-autocomplete-input {
    background-color: transparent;
    border-top-color: #b8bec4;
    border-bottom-color: #b8bec4;
    border-left-color: #b8bec4;
    border-right-color: 1px solid transparent;
}

.p-rowgroup-header{
    background-color: #f8f9fa !important;
}

.center{
    display: flex;
    align-items: center;
}

.p-inputtext {
    border: 1px solid #b8bec4;
}

.p-multiselect {
    border: 1px solid #b8bec4;
}

.p-dropdown {
    border: 1px solid #b8bec4;
}

.p-checkbox .p-checkbox-box {
    border: 2px solid #b8bec4;
}
