
 .p-datepicker table td {
    padding: 0.3rem;
}
.p-datepicker .p-datepicker-header {
    padding: 0.3rem;
    color: #495057;
    background: #ffffff;
    font-weight: 600;
    margin: 0;
    border-bottom: 1px solid #dee2e6;
    border-top-right-radius: 3px;
    border-top-left-radius: 3px;
}

.p-datepicker table td > span {
    border-radius: 50%;
    -webkit-transition: -webkit-box-shadow 0.2s;
    transition: -webkit-box-shadow 0.2s;
    transition: box-shadow 0.2s;
    transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
    border: 1px solid transparent;
    width: 1.7rem; 
    height: 1.7rem;
    
}

.p-datepicker .p-datepicker-buttonbar {
    padding: 0;
    border-top: 1px solid #dee2e6;
}

.p-datatable .p-datatable-thead > tr > th {
    text-align: left;
    padding: 1rem 0.8rem;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    font-weight: 600;
    color: #495057;
    background: #f8f9fa;
    -webkit-transition: -webkit-box-shadow 0.2s;
    transition: -webkit-box-shadow 0.2s;
    transition: box-shadow 0.2s;
    transition: box-shadow 0.2s, -webkit-box-shadow 0.2s;
}

.p-inputtextarea{
    height: 33px;
}

.p-datepickerCalendar table td > span.p-highlight {
    color: #495057;
    background: #b662c0;
}

.p-datepicker table td > span.p-highlight {
    color: #495057;
    background: #b662c0;
}

.p-datepicker table td.p-datepicker-today > span.p-highlight {
    color: #495057;
    background: #b662c0;
}   
/* Día festivo */
.holiday {
    background-color: #fff; 
    color: #495057; 
    border-radius: 50%; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 1.5rem; 
    height: 1.5rem;
    border: 0.2rem solid #b662c0; 
    font-size: 1.1rem; 
    line-height: 1; 
    margin: auto; 
}

/* Día festivo seleccionado */
.holiday-selected {
    background-color: #93a0ad; 
    color: #fff; 
    border-radius: 50%; 
    display: flex; 
    justify-content: center; 
    align-items: center; 
    width: 2rem; 
    border: 0.2rem solid #7e8a96; 
    font-size: 1.1rem; 
    line-height: 1; 
    margin: auto; 
}



