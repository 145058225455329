.datatable-responsive-demo .p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
    display: none;
}

@media screen and (max-width: 960px) {
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-thead > tr > th,
    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tfoot > tr > td {
        display: none !important;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td {
        text-align: left;
        display: block;
        width: 100%;
        float: left;
        clear: left;
        border: 0 none;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td .p-column-title {
        padding: .4rem;
        min-width: 30%;
        display: inline-block;
        margin: -.4em 1em -.4em -.4rem;
        font-weight: bold;
    }

    .datatable-responsive-demo .p-datatable.p-datatable-responsive-demo .p-datatable-tbody > tr > td:last-child {
        border-bottom: 1px solid var(--surface-d);
    }
}


.datatable-scroll-demo .loading-text {
    display: block;
    background-color: #f1f1f1;
    min-height: 19px;
    animation: pulse 1s infinite ease-in-out;
    text-indent: -99999px;
    overflow: hidden;
}

.calendar{
    text-align: center;
}
.p-column-filter {
    width: 100%;
    
}
.p-dropdown-panel .p-dropdown-items {
    padding: 0.5rem 0;
    z-index: 999;
    max-height: 150px;
    max-width: 400px;
    background: #ffffff;
    color: #495057;
    border: 0 none;
    border-radius: 3px;
    left: 0px;
    /* box-shadow: 0 2px 4px -1px rgb(0 0 0 / 20%), 0 4px 5px 0 rgb(0 0 0 / 14%), 0 1px 10px 0 rgb(0 0 0 / 12%); */
}

@media screen and (max-width: 485px) {
    .p-dropdown-panel .p-dropdown-items {
       
        max-width: 300px;
        
    }
}
@media screen and (max-width: 380px) {
    .p-dropdown-panel .p-dropdown-items {
       
        max-width: 200px;
        
    }
}
@media screen and (max-width: 265px) {
    .p-dropdown-panel .p-dropdown-items {
       
        max-width: 150px;
        
    }
}


.p-datatable-scrollable-header{
    overflow: visible;
}

.columna{
    overflow: hidden;
}


.p-datepicker-calendar {

    text-align: center;
}

.btnAdd{
    background-color: #77b83e !important;
    margin: 0;
    display: inline-flex;
    align-items: center;
    border: 1px solid !important;
    outline: none;
    border-radius: 5px;
    color: #fff;
    cursor: pointer;
    transition: 0.5s;
    padding: 7px 20px;
    font-size: 14px;
    text-decoration: none;
    /* width: 100%; */
}
.Add{
    
    margin-right: 5px;
    font-size: 12px;
}

.delete {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
}

.btnEditar{
    color: #444;
    background: transparent;
    border: 1px solid transparent;
    margin-left: 5px;
}

.btnEditar:hover {
    color: #444;
    background: transparent;
    border: 1px solid transparent;
}

.headerTable{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-wrap: wrap;
    
}

.btnTrash{
    margin-top: 5px;
    margin-left: 5px;
}

.btnRegresar{
    color: #7B139B;
    background-color: transparent;
    border-color: transparent;
    
}

.breadcrumbs {
    padding: 10px;
    margin-left: 20px;
}

.breadcrumbsItem{
    display: inline-block;
}

.breadcrumbsItem:not(:last-of-type)::after{
    content: '/';
    font-size: 15px;
    margin: 0 5px;
    color: #cccccc;
    float: right;
}

.breadcrubsLink{
    text-decoration: none;
    
    color: #999999;
}

.breadcrubsLink:hover{
    font-weight: 600;
    color: #7B139B;
}
.disabled{
    color: #999999;
}

.Botones{
    display: flex;
}

.Acciones{
    text-align: right;
}
         

.mensajeCofirmar{
    display: flex;
    justify-content: center;
    align-items: center;
}

.ContainerBtnRegresar{
    display: grid;
    justify-items: center;
    margin-top: 16px;
}

.BtnRegresar{
    padding: 12px;
    background: #7B139B;
    text-decoration: none;
    float: right;
    border-radius: 5px;
    color: #fff;
    transition: 0.5s;
    
}
.regresar{
    text-decoration: none;
}
.DatosFooter {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    /* height: 9em;
    margin-top: 2em; */
}

.ExportTittle{
    margin-bottom: 10px;
}


.fila{
    text-align: left;
    border: 1px solid #e9ecef;
    border-width: 0 0 1px 0;
    padding: 1rem 1rem;
    font-weight: 300;
}

/* .p-datatable table {
    border-collapse: collapse;
    width: 100%;
    table-layout: fixed;
    overflow: visible;
}
 */

 .fechaFlilter .p-datepicker{
    /* background-color: red; */
    overflow: scroll;
    max-height: 190px;
    
 }

 /* .p-multiselect .p-multiselect-panel {
    max-width: 100%;
} */


.datatable-rowgroup-demo .p-rowgroup-footer td {
    font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header span {
    font-weight: 700;
}

.datatable-rowgroup-demo .p-rowgroup-header .p-row-toggler {
    vertical-align: middle;
    margin-right: .25rem;
}

.buttonExportHistorico {
    display: flex;
    justify-content: flex-end;

}

.p-datatable .p-datatable-tbody > tr.p-highlight {
    background: transparent;
    color: #495057;
}

.tittleChart {
    color: #444;
    text-align: center;
    font-size: 14px;
    padding: 1em;
}

.limpiar {
    display: flex;
    justify-content: center;
    margin-left: 10px;
}


.p-slider {
    background: #93979c !important;
}

.separador-vertical {
    width: 60px;
    height: 4px;
    background-color:#959596;
    margin: 24px;
    transform: rotate(90deg);
}

.p-radiobutton .p-radiobutton-box {
    border: 2px solid #b1b1b1 !important;
}

.p-radiobutton .p-radiobutton-box.p-highlight {
    border-color: #2196F3 !important;
    background: #2196F3;
}

.text-center{
    text-align: center;
}

.center-rd{
    justify-content: center;
}

.btn-report{
    display: flex;
    justify-content: flex-end
}