.p-field-checkbox > label, .p-field-radiobutton > label {
    margin-right: 0.5rem !important;
}
/* .p-jc-center {
    justify-content: space-around;
} */

.p-d-flex {
    display: flex !important;
    flex-wrap: wrap !important;
}